import React, { Component } from "react";
import { unsupervisedStartTraining } from "../../../actions/actions";
import ClassNameList from "../../../components/ClassNameList";
import TrainingDialog from "../../../components/Augmentation/TrainingDialog";
import AutoClassifierDialog from "../../../components/AutoClassifierDialog";
import {
  CON_MIN_HEIGHT_HEADER,
  CON_MIN_HEIGHT_HEADER_PADDING,
} from "../../../theme";
import ImageListWithMarkers from "../../../components/ImageListWithMarkers/ImageListWithMarkers";
import { calcBorder, imagesAutoClassification } from "../../../utils/common";
import PatchSize from "../../../components/PatchSize";
import ModuleRightToolPanel from "../../../components/ModuleRightToolPanel";
import ModuleSettingsWrap from "../../../components/ModuleSettingsWrap";
import TrainingButton from "../../../components/TrainingButton";
import { RadioGroup, FormControlLabel, Radio } from "@mui/material";
import { PropsFromRedux } from "../TrainingContainer";
import withI18n from "../../../utils/withI18n";
import { Image } from "../../../types/common";
import { History } from "history";
import { FilterImage } from "../../../utils/sortFilterImages";
import { HelperContext } from "../../../layouts/PageLayout/PageLayout";

const styles = {
  classifier: {
    width: 160,
    marginTop: 41,
  },
  contentWrap: {
    flexFlow: "row",
    justifyContent: "space-around",
    display: "flex",
    alignItems: "stretch",
    height: "100%",
  },
};

type Props = PropsFromRedux & { t: (key: string) => string; history: History };

type State = {
  patchSize: number;
  selectedIds: number[];
  showAutoClassifierDialog: boolean;
};

const isInvalidTutorialImages = (okIds: number[]) => {
  return okIds.some((id) => id < 6);
};

class TrainingView extends Component<Props, State> {
  static contextType = HelperContext;
  context!: React.ContextType<typeof HelperContext>;

  state: State = {
    patchSize: 0,
    selectedIds: [],
    showAutoClassifierDialog: false,
  };

  componentDidMount() {
    this.setState({
      patchSize: this.props.module.values.config.PATCH_SIZE,
    });
  }

  componentDidUpdate(prevProps: Props) {
    if (
      prevProps.module.values.config.PATCH_SIZE !==
      this.props.module.values.config.PATCH_SIZE
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        patchSize: this.props.module.values.config.PATCH_SIZE,
      });
    }
  }

  handleClickAutoClassification = (e: React.MouseEvent) => {
    e.stopPropagation();
    this.setState({
      showAutoClassifierDialog: true,
    });
  };

  getCurrentClass = () => {
    const annotations = this.props.module.values.classification;
    const ok = annotations.ok;
    const nok = annotations.nok;

    const ids = [...this.state.selectedIds];
    if (ids.filter((i) => ok.indexOf(i) !== -1).length === ids.length) {
      return "ok";
    }
    if (ids.filter((i) => nok.indexOf(i) !== -1).length === ids.length) {
      return "nok";
    }
    if (ids.filter((i) => ok.indexOf(i) !== -1).length === 0) {
      return null;
    }
    return undefined;
  };

  getImageItem = (i: Image) => {
    const _ = this.props.t;
    const ok = this.props.module.values.classification.ok.indexOf(i.id) !== -1;
    const nok =
      this.props.module.values.classification.nok.indexOf(i.id) !== -1;
    return {
      ...i,
      isOk: ok,
      isNok: nok,
      label2: `${ok ? _("ok") : ""}${nok ? _("ng") : ""}`,
      isMarked: ok || nok,
    };
  };

  getImages = (): FilterImage[] => {
    const _ = this.props.t;
    const r = this.props.images.map((i) => {
      const ok =
        this.props.module.values.classification.ok.indexOf(i.id) !== -1;
      const nok =
        this.props.module.values.classification.nok.indexOf(i.id) !== -1;
      return {
        ...i,
        isOk: ok,
        isNok: nok,
        label2: `${ok ? _("ok") : ""}${nok ? _("ng") : ""}`,
        isMarked: ok || nok,
      };
    });
    return r;
  };

  getTrainingError = () => {
    const { ok } = this.props.module.values.classification;
    const _ = this.props.t;
    if (ok.length === 0) {
      return _("unsupervised_error_min_ok");
    } else if (isInvalidTutorialImages(ok)) {
      return _("unsupervised_error_tutorial_images");
    }
  };

  render() {
    const _ = this.props.t;
    const moduleId = this.props.module.id;
    const { config } = this.props.module.values;
    const { ok, nok } = this.props.module.values.classification;
    const classNames = [
      { id: "ok", label: _("ok"), count: ok.length },
      /*{ id: "nok", label: _("ng"), count: nok?.length ?? 0 },*/
    ];

    const patchSize = this.props.module.values.config.PATCH_SIZE;
    const isOld = config.TYPE === "old";
    return (
      <div>
        <ImageListWithMarkers
          ImageDetailProps={{
            patchSize: isOld ? this.state.patchSize : undefined,
            paddingBorder: isOld
              ? ({ width, height }) => calcBorder(patchSize, width, height)
              : undefined,
          }}
          ImageListProps={{
            showSelectedFilter: true,
            selectMore: true,
            onSelectMore: (ids) =>
              this.setState({
                selectedIds: ids,
              }),
            images: this.getImages(),
          }}
          detailHeight={CON_MIN_HEIGHT_HEADER_PADDING}
          listHeight={CON_MIN_HEIGHT_HEADER}
          onSelectImage={(image) =>
            this.setState({
              selectedIds: [image.id],
            })
          }
          disabledKeyListener={
            this.props.showTrainingDialog || this.state.showAutoClassifierDialog
          }
          moduleId={moduleId}
          readOnly
          getImageSrc={(imageId) =>
            this.context.helper.getImagePath({
              imageId,
              moduleId,
              includeLast: false,
            })
          }
        >
          <div style={styles.contentWrap}>
            <ModuleRightToolPanel>
              {!this.props.simpleTutorialOnly && (
                <ModuleSettingsWrap title={_("type")}>
                  <RadioGroup
                    value={config.TYPE || "old"}
                    onChange={(e) => {
                      this.props.anomalyEditConfig({
                        moduleId,
                        config: {
                          ...config,
                          TYPE: e.target.value,
                        },
                      });
                    }}
                  >
                    <FormControlLabel
                      value="new"
                      control={<Radio />}
                      label={_("standart")}
                    />
                    <FormControlLabel
                      value="old"
                      control={<Radio />}
                      label={_("lightweight")}
                    />
                  </RadioGroup>
                </ModuleSettingsWrap>
              )}

              {config.TYPE !== "new" && (
                <PatchSize
                  value={this.state.patchSize}
                  onConfirm={(value) => {
                    this.props.anomalyEditConfig({
                      moduleId,
                      config: {
                        ...config,
                        PATCH_SIZE: value,
                      },
                    });
                  }}
                  onChange={(patchSize) => {
                    this.setState({
                      patchSize,
                    });
                  }}
                  min={22}
                  max={94}
                  step={8}
                />
              )}
              <ModuleSettingsWrap title={_("annotations")}>
                <ClassNameList
                  t={this.props.t}
                  isActive
                  onClickAutoClassifier={this.handleClickAutoClassification}
                  onClickRemove={() =>
                    this.props.anomalyClearAnnotations(this.props.module.id)
                  }
                  onChange={(id) => {
                    if (this.props.showTrainingDialog) {
                      return;
                    }

                    let nextOk = ok;
                    let nextNok = nok;

                    const selectedIds = [...this.state.selectedIds];

                    if (id === "ok") {
                      nextOk = Array.from(new Set([...ok, ...selectedIds]));
                      nextNok = nok.filter((i) => nextOk.indexOf(i) === -1);
                    } else if (id === "nok") {
                      nextNok = Array.from(new Set([...nok, ...selectedIds]));
                      nextOk = ok.filter((i) => nextNok.indexOf(i) === -1);
                    } else {
                      nextOk = ok.filter((i) => selectedIds.indexOf(i) === -1);
                      nextNok = nok.filter(
                        (i) => selectedIds.indexOf(i) === -1
                      );
                    }
                    this.props.anomalySetImages({
                      moduleId,
                      nok: nextNok,
                      ok: nextOk,
                    });
                  }}
                  value={this.getCurrentClass()}
                  items={classNames}
                />
              </ModuleSettingsWrap>
              {!this.props.trainingDisabled && (
                <ModuleSettingsWrap>
                  <TrainingButton
                    onClick={() => {
                      this.props.history.replace(
                        `/modules/unsupervised/${this.props.module.id}/training/?showTrainingDialog`
                      );
                    }}
                  />
                </ModuleSettingsWrap>
              )}
            </ModuleRightToolPanel>
          </div>
        </ImageListWithMarkers>
        <TrainingDialog
          modelsCounter={this.props.modelsCounter}
          open={this.props.showTrainingDialog}
          hiddenExtendModel
          hiddenNetworkSize
          allowFlip={isOld}
          allowEpochSize={
            isOld && !this.props.module.values.config.FAST_TRAINING
          }
          allowBrightnessResistance={isOld}
          allowNoiseResistance={isOld}
          onClose={() =>
            this.props.history.replace(
              `/modules/unsupervised/${this.props.module.id}/training/`
            )
          }
          models={this.props.models}
          config={this.props.module.values.config}
          onChangeConfig={(config) =>
            this.props.anomalyEditConfig({ moduleId, config })
          }
          onStartTraining={(name) => {
            this.props.startAnomalyTraining();
            unsupervisedStartTraining({
              moduleId,
              flow: this.context.helper.getSettings({ moduleId: moduleId }),
              name,
              faster: this.props.module.values.config.FAST_TRAINING,
              okIds: this.props.module.values.classification.ok,
            });
          }}
          allowFaster={isOld}
          errorMessage={this.getTrainingError()}
        />
        <AutoClassifierDialog
          open={this.state.showAutoClassifierDialog}
          onClose={() => this.setState({ showAutoClassifierDialog: false })}
          classNames={classNames}
          onSet={(patternItems, isRegex) => {
            const { results, error } = imagesAutoClassification(
              this.props.images,
              patternItems,
              isRegex
            );
            if (error) {
              this.props.addErrorMessage(error);
            }
            this.props.unsupervisedAuto({
              moduleId,
              ok: results[0],
              nok: [],
            });
            this.setState({
              showAutoClassifierDialog: false,
            });
          }}
        />
      </div>
    );
  }
}

export default withI18n(TrainingView);
