/* eslint-disable no-restricted-globals */
const SERVER_IP = "localhost";
const PORT = 8200;

export const SERVER_URL =
  process.env.NODE_ENV === "development" ? `http://${SERVER_IP}:${PORT}` : "";
export const SERVER_IMAGE_URL =
  process.env.NODE_ENV === "development"
    ? `http://${SERVER_IP}:${PORT + 0}`
    : window.location.origin;

export const SERVER_LIVESTREAM_URL = SERVER_IMAGE_URL;
